import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import YoutubeVideo from "@src/components/YoutubeVideo";

import { styles } from "./OurVideos.styles";

export type PressTemplateProps = WithStyles<typeof styles> & {
  className?: string;
};

class OurVideos extends React.PureComponent<PressTemplateProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={cn(className, classes.videosContainer)}>
        <YoutubeVideo videoId="TTDtYyLOrbU" className={classes.video} />
        <YoutubeVideo videoId="CiER4cd0YQM" className={classes.video} />
        <YoutubeVideo videoId="Sug0FY1zIUo" className={classes.video} />
        <YoutubeVideo videoId="07CqhwAhcTU" className={classes.video} />
        <YoutubeVideo videoId="a-CSpIuD7-I" className={classes.video} />
        <YoutubeVideo videoId="oXgqLJ_kNYk" className={classes.video} />
        <YoutubeVideo videoId="48tRCCkbkqg" className={classes.video} />
        <YoutubeVideo videoId="PhUWzv_fWVA" className={classes.video} />
        <YoutubeVideo videoId="zSuo7KPyWEQ" className={classes.video} />
      </div>
    );
  }
}

export default withStyles(styles)(OurVideos);
